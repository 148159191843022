import http from './fetch.js';

// 获取会员信息
export function getOnlineMember(params = {}) {
    return http({
        url: '/web/client/memberInfo',
        method: 'get',
        params: params
    });
}
// 获取会员详情
export function getMemberInfo(params = {}) {
    return http({
        url: '/web/info',
        method: 'get',
        params: params
    });
}

// 搜索会员
export function searchMember(params = {}) {
    return http({
        url: '/web/client/searchMember',
        method: 'get',
        params: params
    });
}

// 下机
export function memberDeplane(params = {}) {
    return http({
        url: '/web/client/deplaneV2',
        method: 'post',
        params: params
    });
}

// 获取余额
export function memberBalance(params = {}) {
  return http({
      url: '/web/client/balance',
      method: 'get',
      params: params
  });
}

