import http from './fetch.js';

// 获取评论列表
export function getCommentList(params = {}) {
  return http({
    url: '/web/client/commentList',
    method: 'get',
    params: params
  });
}

// 发布评论
export function addComment(params = {}) {
  return http({
      url: '/web/client/comment',
      method: 'post',
      params:params
  });
}


