export default [
  {
    "cn": "阿富汗",
    "en": "Afghanistan",
    "full": "the Islamic Republic of Afghanistan",
    "abb2": "AF",
    "abb3": "AFG",
    "code": "004"
  },
  {
    "cn": "奥兰群岛",
    "en": "Aland Islands",
    "full": "Aland Islands",
    "abb2": "AX",
    "abb3": "ALA",
    "code": "248"
  },
  {
    "cn": "阿尔巴尼亚",
    "en": "Albania",
    "full": "the Republic of Albania",
    "abb2": "AL",
    "abb3": "ALB",
    "code": "008"
  },
  {
    "cn": "阿尔及利亚",
    "en": "Algeria",
    "full": "the People's Democratic Republic of Algeria",
    "abb2": "DZ",
    "abb3": "DZA",
    "code": "012"
  },
  {
    "cn": "美属萨摩亚",
    "en": "American Samoa",
    "full": "American Samoa",
    "abb2": "AS",
    "abb3": "ASM",
    "code": "016"
  },
  {
    "cn": "安道尔",
    "en": "Andorra",
    "full": "the Principality of Andorra",
    "abb2": "AD",
    "abb3": "AND",
    "code": "020"
  },
  {
    "cn": "安哥拉",
    "en": "Angola",
    "full": "the Republic of Angola",
    "abb2": "AO",
    "abb3": "AGO",
    "code": "024"
  },
  {
    "cn": "安圭拉",
    "en": "Anguilla",
    "full": "Anguilla",
    "abb2": "AI",
    "abb3": "AIA",
    "code": "660"
  },
  {
    "cn": "南极洲",
    "en": "Antarctica",
    "full": "Antarctica",
    "abb2": "AQ",
    "abb3": "ATA",
    "code": "010"
  },
  {
    "cn": "安提瓜和巴布达",
    "en": "Antigua and Barbuda",
    "full": "Antigua and Barbuda",
    "abb2": "AG",
    "abb3": "ATG",
    "code": "028"
  },
  {
    "cn": "阿根廷",
    "en": "Argentina",
    "full": "the Argentine Republic",
    "abb2": "AR",
    "abb3": "ARG",
    "code": "032"
  },
  {
    "cn": "亚美尼亚",
    "en": "Armenia",
    "full": "the Republic of Armenia",
    "abb2": "AM",
    "abb3": "ARM",
    "code": "051"
  },
  {
    "cn": "阿鲁巴",
    "en": "Aruba",
    "full": "Aruba",
    "abb2": "AW",
    "abb3": "ABW",
    "code": "533"
  },
  {
    "cn": "澳大利亚",
    "en": "Australia",
    "full": "Australia",
    "abb2": "AU",
    "abb3": "AUS",
    "code": "036"
  },
  {
    "cn": "奥地利",
    "en": "Austria",
    "full": "the Republic of Austria",
    "abb2": "AT",
    "abb3": "AUT",
    "code": "040"
  },
  {
    "cn": "阿塞拜疆",
    "en": "Azerbaijan",
    "full": "the Republic of Azerbaijan",
    "abb2": "AZ",
    "abb3": "AZE",
    "code": "031"
  },
  {
    "cn": "巴哈马",
    "en": "Bahamas",
    "full": "the Commonwealth of The Bahamas",
    "abb2": "BS",
    "abb3": "BHS",
    "code": "044"
  },
  {
    "cn": "巴林",
    "en": "Bahrain",
    "full": "the Kingdom of Bahrain",
    "abb2": "BH",
    "abb3": "BHR",
    "code": "048"
  },
  {
    "cn": "孟加拉国",
    "en": "Bangladesh",
    "full": "the People's Republic of Bangladesh",
    "abb2": "BD",
    "abb3": "BGD",
    "code": "050"
  },
  {
    "cn": "巴巴多斯",
    "en": "Barbados",
    "full": "Barbados",
    "abb2": "BB",
    "abb3": "BRB",
    "code": "052"
  },
  {
    "cn": "白俄罗斯",
    "en": "Belarus",
    "full": "the Republic of Belarus",
    "abb2": "BY",
    "abb3": "BLR",
    "code": "112"
  },
  {
    "cn": "比利时",
    "en": "Belgium",
    "full": "the Kingdom of Belgium",
    "abb2": "BE",
    "abb3": "BEL",
    "code": "056"
  },
  {
    "cn": "伯利兹",
    "en": "Belize",
    "full": "Belize",
    "abb2": "BZ",
    "abb3": "BLZ",
    "code": "084"
  },
  {
    "cn": "贝宁",
    "en": "Benin",
    "full": "the Republic of Benin",
    "abb2": "BJ",
    "abb3": "BEN",
    "code": "204"
  },
  {
    "cn": "百慕大",
    "en": "Bermuda",
    "full": "Bermuda",
    "abb2": "BM",
    "abb3": "BMU",
    "code": "060"
  },
  {
    "cn": "不丹",
    "en": "Bhutan",
    "full": "the Kingdom of Bhutan",
    "abb2": "BT",
    "abb3": "BTN",
    "code": "064"
  },
  {
    "cn": "玻利维亚",
    "en": "Bolivia",
    "full": "the Republic of Bolivia",
    "abb2": "BO",
    "abb3": "BOL",
    "code": "068"
  },
  {
    "cn": "波黑",
    "en": "Bosnia and Herzegovina",
    "full": "Bosnia and Herzegovina",
    "abb2": "BA",
    "abb3": "BIH",
    "code": "070"
  },
  {
    "cn": "博茨瓦纳",
    "en": "Botswana",
    "full": "the Republic of Botswana",
    "abb2": "BW",
    "abb3": "BWA",
    "code": "072"
  },
  {
    "cn": "布维岛",
    "en": "Bouvet Island",
    "full": "Bouvet Island",
    "abb2": "BV",
    "abb3": "BVT",
    "code": "074"
  },
  {
    "cn": "巴西",
    "en": "Brazil",
    "full": "the Federative Republic of Brazil",
    "abb2": "BR",
    "abb3": "BRA",
    "code": "076"
  },
  {
    "cn": "英属印度洋领地",
    "en": "British Indian Ocean Territory",
    "full": "British Indian Ocean Territory",
    "abb2": "IO",
    "abb3": "IOT",
    "code": "086"
  },
  {
    "cn": "英格兰",
    "en": "British Indian Ocean England",
    "full": "British Indian Ocean England",
    "abb2": "ENG",
    "abb3": "ENG",
    "code": "086"
  },
  {
    "cn": "文莱",
    "en": "Brunei Darussalam",
    "full": "Brunei Darussalam",
    "abb2": "BN",
    "abb3": "BRN",
    "code": "096"
  },
  {
    "cn": "保加利亚",
    "en": "Bulgaria",
    "full": "the Republic of Bulgaria",
    "abb2": "BG",
    "abb3": "BGR",
    "code": "100"
  },
  {
    "cn": "布基纳法索",
    "en": "Burkina Faso",
    "full": "Burkina Faso",
    "abb2": "BF",
    "abb3": "BFA",
    "code": "854"
  },
  {
    "cn": "布隆迪",
    "en": "Burundi",
    "full": "the Republic of Burundi",
    "abb2": "BI",
    "abb3": "BDI",
    "code": "108"
  },
  {
    "cn": "柬埔寨",
    "en": "Cambodia",
    "full": "the Kingdom of Cambodia",
    "abb2": "KH",
    "abb3": "KHM",
    "code": "116"
  },
  {
    "cn": "喀麦隆",
    "en": "Cameroon",
    "full": "the Republic of Cameroon",
    "abb2": "CM",
    "abb3": "CMR",
    "code": "120"
  },
  {
    "cn": "加拿大",
    "en": "Canada",
    "full": "Canada",
    "abb2": "CA",
    "abb3": "CAN",
    "code": "124"
  },
  {
    "cn": "佛得角",
    "en": "Cape Verde",
    "full": "the Republic of Cape Verde",
    "abb2": "CV",
    "abb3": "CPV",
    "code": "132"
  },
  {
    "cn": "开曼群岛",
    "en": "Cayman Islands",
    "full": "Cayman Islands",
    "abb2": "KY",
    "abb3": "CYM",
    "code": "136"
  },
  {
    "cn": "中非",
    "en": "Central African Republic",
    "full": "the Central African Republic",
    "abb2": "CF",
    "abb3": "CAF",
    "code": "140"
  },
  {
    "cn": "乍得",
    "en": "Chad",
    "full": "the Republic of Chad",
    "abb2": "TD",
    "abb3": "TCD",
    "code": "148"
  },
  {
    "cn": "智利",
    "en": "Chile",
    "full": "the Republic of Chile",
    "abb2": "CL",
    "abb3": "CHL",
    "code": "152"
  },
  {
    "cn": "中国",
    "en": "China",
    "full": "the People's Republic of China",
    "abb2": "CN",
    "abb3": "CHN",
    "code": "156"
  },
  {
    "cn": "圣诞岛",
    "en": "Christmas Island",
    "full": "Christmas Island",
    "abb2": "CX",
    "abb3": "CXR",
    "code": "162"
  },
  {
    "cn": "科科斯（基林）群岛",
    "en": "Cocos (Keeling) Islands",
    "full": "Cocos (Keeling) Islands",
    "abb2": "CC",
    "abb3": "CCK",
    "code": "166"
  },
  {
    "cn": "哥伦比亚",
    "en": "Colombia",
    "full": "the Republic of Colombia",
    "abb2": "CO",
    "abb3": "COL",
    "code": "170"
  },
  {
    "cn": "科摩罗",
    "en": "Comoros",
    "full": "the Union of the Comoros",
    "abb2": "KM",
    "abb3": "COM",
    "code": "174"
  },
  {
    "cn": "刚果（布）",
    "en": "Congo",
    "full": "the Republic of the Congo",
    "abb2": "CG",
    "abb3": "COG",
    "code": "178"
  },
  {
    "cn": "刚果（金）",
    "en": "Congo (the Democratic Republic of the)",
    "full": "the Democratic Republic of the Congo",
    "abb2": "CD",
    "abb3": "COD",
    "code": "180"
  },
  {
    "cn": "库克群岛",
    "en": "Cook Islands",
    "full": "Cook Islands",
    "abb2": "CK",
    "abb3": "COK",
    "code": "184"
  },
  {
    "cn": "哥斯达黎加",
    "en": "Costa Rica",
    "full": "the Republic of Costa Rica",
    "abb2": "CR",
    "abb3": "CRI",
    "code": "188"
  },
  {
    "cn": "科特迪瓦",
    "en": "Côte d'Ivoire",
    "full": "the Republic of Côte d'Ivoire",
    "abb2": "CI",
    "abb3": "CIV",
    "code": "384"
  },
  {
    "cn": "克罗地亚",
    "en": "Croatia",
    "full": "the Republic of Croatia",
    "abb2": "HR",
    "abb3": "HRV",
    "code": "191"
  },
  {
    "cn": "古巴",
    "en": "Cuba",
    "full": "the Republic of Cuba",
    "abb2": "CU",
    "abb3": "CUB",
    "code": "192"
  },
  {
    "cn": "塞浦路斯",
    "en": "Cyprus",
    "full": "the Republic of Cyprus",
    "abb2": "CY",
    "abb3": "CYP",
    "code": "196"
  },
  {
    "cn": "捷克",
    "en": "Czech Republic",
    "full": "the Czech Republic",
    "abb2": "CZ",
    "abb3": "CZE",
    "code": "203"
  },
  {
    "cn": "丹麦",
    "en": "Denmark",
    "full": "the Kingdom of Denmark",
    "abb2": "DK",
    "abb3": "DNK",
    "code": "208"
  },
  {
    "cn": "吉布提",
    "en": "Djibouti",
    "full": "the Republic of Djibouti",
    "abb2": "DJ",
    "abb3": "DJI",
    "code": "262"
  },
  {
    "cn": "多米尼克",
    "en": "Dominica",
    "full": "the Commonwealth of Dominica",
    "abb2": "DM",
    "abb3": "DMA",
    "code": "212"
  },
  {
    "cn": "多米尼加",
    "en": "Dominican Republic",
    "full": "the Dominican Republic",
    "abb2": "DO",
    "abb3": "DOM",
    "code": "214"
  },
  {
    "cn": "厄瓜多尔",
    "en": "Ecuador",
    "full": "the Republic of Ecuador",
    "abb2": "EC",
    "abb3": "ECU",
    "code": "218"
  },
  {
    "cn": "埃及",
    "en": "Egypt",
    "full": "the Arab Republic of Egypt",
    "abb2": "EG",
    "abb3": "EGY",
    "code": "818"
  },
  {
    "cn": "萨尔瓦多",
    "en": "El Salvador",
    "full": "the Republic of El Salvador",
    "abb2": "SV",
    "abb3": "SLV",
    "code": "222"
  },
  {
    "cn": "赤道几内亚",
    "en": "Equatorial Guinea",
    "full": "the Republic of Equatorial Guinea",
    "abb2": "GQ",
    "abb3": "GNQ",
    "code": "226"
  },
  {
    "cn": "厄立特里亚",
    "en": "Eritrea",
    "full": "Eritrea",
    "abb2": "ER",
    "abb3": "ERI",
    "code": "232"
  },
  {
    "cn": "爱沙尼亚",
    "en": "Estonia",
    "full": "the Republic of Estonia",
    "abb2": "EE",
    "abb3": "EST",
    "code": "233"
  },
  {
    "cn": "埃塞俄比亚",
    "en": "Ethiopia",
    "full": "the Federal Democratic Republic of Ethiopia",
    "abb2": "ET",
    "abb3": "ETH",
    "code": "231"
  },
  {
    "cn": "福克兰群岛（马尔维纳斯）",
    "en": "Falkland Islands [Malvinas]",
    "full": "Falkland Islands [Malvinas]",
    "abb2": "FK",
    "abb3": "FLK",
    "code": "238"
  },
  {
    "cn": "法罗群岛",
    "en": "Faroe Islands",
    "full": "Faroe Islands",
    "abb2": "FO",
    "abb3": "FRO",
    "code": "234"
  },
  {
    "cn": "斐济",
    "en": "Fiji",
    "full": "the Republic of the Fiji Islands",
    "abb2": "FJ",
    "abb3": "FJI",
    "code": "242"
  },
  {
    "cn": "芬兰",
    "en": "Finland",
    "full": "the Republic of Finland",
    "abb2": "FI",
    "abb3": "FIN",
    "code": "246"
  },
  {
    "cn": "法国",
    "en": "France",
    "full": "the French Republic",
    "abb2": "FR",
    "abb3": "FRA",
    "code": "250"
  },
  {
    "cn": "法属圭亚那",
    "en": "French Guiana",
    "full": "French Guiana",
    "abb2": "GF",
    "abb3": "GUF",
    "code": "254"
  },
  {
    "cn": "法属波利尼西亚",
    "en": "French Polynesia",
    "full": "French Polynesia",
    "abb2": "PF",
    "abb3": "PYF",
    "code": "258"
  },
  {
    "cn": "法属南部领地",
    "en": "French Southern Territories",
    "full": "French Southern Territories",
    "abb2": "TF",
    "abb3": "ATF",
    "code": "260"
  },
  {
    "cn": "加蓬",
    "en": "Gabon",
    "full": "the Gabonese Republic",
    "abb2": "GA",
    "abb3": "GAB",
    "code": "266"
  },
  {
    "cn": "冈比亚",
    "en": "Gambia",
    "full": "the Republic of The Gambia",
    "abb2": "GM",
    "abb3": "GMB",
    "code": "270"
  },
  {
    "cn": "格鲁吉亚",
    "en": "Georgia",
    "full": "Georgia",
    "abb2": "GE",
    "abb3": "GEO",
    "code": "268"
  },
  {
    "cn": "德国",
    "en": "Germany",
    "full": "he Federal Republic of Germany",
    "abb2": "DE",
    "abb3": "DEU",
    "code": "276"
  },
  {
    "cn": "加纳",
    "en": "Ghana",
    "full": "the Republic of Ghana",
    "abb2": "GH",
    "abb3": "GHA",
    "code": "288"
  },
  {
    "cn": "直布罗陀",
    "en": "Gibraltar",
    "full": "Gibraltar",
    "abb2": "GI",
    "abb3": "GIB",
    "code": "292"
  },
  {
    "cn": "希腊",
    "en": "Greece",
    "full": "the Hellenic Republic",
    "abb2": "GR",
    "abb3": "GRC",
    "code": "300"
  },
  {
    "cn": "格陵兰",
    "en": "Greenland",
    "full": "Greenland",
    "abb2": "GL",
    "abb3": "GRL",
    "code": "304"
  },
  {
    "cn": "格林纳达",
    "en": "Grenada",
    "full": "Grenada",
    "abb2": "GD",
    "abb3": "GRD",
    "code": "308"
  },
  {
    "cn": "瓜德罗普",
    "en": "Guadeloupe",
    "full": "Guadeloupe",
    "abb2": "GP",
    "abb3": "GLP",
    "code": "312"
  },
  {
    "cn": "关岛",
    "en": "Guam",
    "full": "Guam",
    "abb2": "GU",
    "abb3": "GUM",
    "code": "316"
  },
  {
    "cn": "危地马拉",
    "en": "Guatemala",
    "full": "the Republic of Guatemala",
    "abb2": "GT",
    "abb3": "GTM",
    "code": "320"
  },
  {
    "cn": "格恩西岛",
    "en": "Guernsey",
    "full": "Guernsey",
    "abb2": "GG",
    "abb3": "GGY",
    "code": "831"
  },
  {
    "cn": "几内亚",
    "en": "Guinea",
    "full": "the Republic of Guinea",
    "abb2": "GN",
    "abb3": "GIN",
    "code": "324"
  },
  {
    "cn": "几内亚比绍",
    "en": "Guinea-Bissau",
    "full": "the Republic of Guinea-Bissau",
    "abb2": "GW",
    "abb3": "GNB",
    "code": "624"
  },
  {
    "cn": "圭亚那",
    "en": "Guyana",
    "full": "the Republic of Guyana",
    "abb2": "GY",
    "abb3": "GUY",
    "code": "328"
  },
  {
    "cn": "海地",
    "en": "Haiti",
    "full": "the Republic of Haiti",
    "abb2": "HT",
    "abb3": "HTI",
    "code": "332"
  },
  {
    "cn": "赫德岛和麦克唐纳岛",
    "en": "Heard Island and McDonald Islands",
    "full": "Heard Island and McDonald Islands",
    "abb2": "HM",
    "abb3": "HMD",
    "code": "334"
  },
  {
    "cn": "梵蒂冈",
    "en": "Holy See [Vatican City State]",
    "full": "Holy See [Vatican City State]",
    "abb2": "VA",
    "abb3": "VAT",
    "code": "336"
  },
  {
    "cn": "洪都拉斯",
    "en": "Honduras",
    "full": "the Republic of Honduras",
    "abb2": "HN",
    "abb3": "HND",
    "code": "340"
  },
  {
    "cn": "香港 (中国)",
    "en": "Hong Kong (China)",
    "full": "the Hong Kong (China) Special Administrative Region of China",
    "abb2": "HK",
    "abb3": "HKG",
    "code": "344"
  },
  {
    "cn": "匈牙利",
    "en": "Hungary",
    "full": "the Republic of Hungary",
    "abb2": "HU",
    "abb3": "HUN",
    "code": "348"
  },
  {
    "cn": "冰岛",
    "en": "Iceland",
    "full": "the Republic of Iceland",
    "abb2": "IS",
    "abb3": "ISL",
    "code": "352"
  },
  {
    "cn": "印度",
    "en": "India",
    "full": "the Republic of India",
    "abb2": "IN",
    "abb3": "IND",
    "code": "356"
  },
  {
    "cn": "印度尼西亚",
    "en": "Indonesia",
    "full": "the Republic of Indonesia",
    "abb2": "ID",
    "abb3": "IDN",
    "code": "360"
  },
  {
    "cn": "伊朗",
    "en": "Iran (the Islamic Republic of)",
    "full": "the Islamic Republic of Iran",
    "abb2": "IR",
    "abb3": "IRN",
    "code": "364"
  },
  {
    "cn": "伊拉克",
    "en": "Iraq",
    "full": "the Republic of Iraq",
    "abb2": "IQ",
    "abb3": "IRQ",
    "code": "368"
  },
  {
    "cn": "爱尔兰",
    "en": "Ireland",
    "full": "Ireland",
    "abb2": "IE",
    "abb3": "IRL",
    "code": "372"
  },
  {
    "cn": "英国属地曼岛",
    "en": "Isle of Man",
    "full": "Isle of Man",
    "abb2": "IM",
    "abb3": "IMN",
    "code": "833"
  },
  {
    "cn": "以色列",
    "en": "Israel",
    "full": "the State of Israel",
    "abb2": "IL",
    "abb3": "ISR",
    "code": "376"
  },
  {
    "cn": "意大利",
    "en": "Italy",
    "full": "the Republic of Italy",
    "abb2": "IT",
    "abb3": "ITA",
    "code": "380"
  },
  {
    "cn": "牙买加",
    "en": "Jamaica",
    "full": "Jamaica",
    "abb2": "JM",
    "abb3": "JAM",
    "code": "388"
  },
  {
    "cn": "日本",
    "en": "Japan",
    "full": "Japan",
    "abb2": "JP",
    "abb3": "JPN",
    "code": "392"
  },
  {
    "cn": "泽西岛",
    "en": "Jersey",
    "full": "Jersey",
    "abb2": "JE",
    "abb3": "JEY",
    "code": "832"
  },
  {
    "cn": "约旦",
    "en": "Jordan",
    "full": "the Hashemite Kingdom of Jordan",
    "abb2": "JO",
    "abb3": "JOR",
    "code": "400"
  },
  {
    "cn": "哈萨克斯坦",
    "en": "Kazakhstan",
    "full": "the Republic of Kazakhstan",
    "abb2": "KZ",
    "abb3": "KAZ",
    "code": "398"
  },
  {
    "cn": "肯尼亚",
    "en": "Kenya",
    "full": "the Republic of Kenya",
    "abb2": "KE",
    "abb3": "KEN",
    "code": "404"
  },
  {
    "cn": "基里巴斯",
    "en": "Kiribati",
    "full": "the Republic of Kiribati",
    "abb2": "KI",
    "abb3": "KIR",
    "code": "296"
  },
  {
    "cn": "朝鲜",
    "en": "Korea (the Democratic People's Republic of)",
    "full": "the Democratic People's Republic of Korea",
    "abb2": "KP",
    "abb3": "PRK",
    "code": "408"
  },
  {
    "cn": "韩国",
    "en": "Korea (the Republic of)",
    "full": "the Republic of Korea",
    "abb2": "KR",
    "abb3": "KOR",
    "code": "410"
  },
  {
    "cn": "科威特",
    "en": "Kuwait",
    "full": "he State of Kuwait",
    "abb2": "KW",
    "abb3": "KWT",
    "code": "414"
  },
  {
    "cn": "吉尔吉斯斯坦",
    "en": "Kyrgyzstan",
    "full": "the Kyrgyz Republic",
    "abb2": "KG",
    "abb3": "KGZ",
    "code": "417"
  },
  {
    "cn": "老挝",
    "en": "Lao People's Democratic Republic",
    "full": "the Lao People's Democratic Republic",
    "abb2": "LA",
    "abb3": "LAO",
    "code": "418"
  },
  {
    "cn": "拉脱维亚",
    "en": "Latvia",
    "full": "the Republic of Latvia",
    "abb2": "LV",
    "abb3": "LVA",
    "code": "428"
  },
  {
    "cn": "黎巴嫩",
    "en": "Lebanon",
    "full": "the Lebanese Republic",
    "abb2": "LB",
    "abb3": "LBN",
    "code": "422"
  },
  {
    "cn": "莱索托",
    "en": "Lesotho",
    "full": "the Kingdom of Lesotho",
    "abb2": "LS",
    "abb3": "LSO",
    "code": "426"
  },
  {
    "cn": "利比里亚",
    "en": "Liberia",
    "full": "the Republic of Liberia",
    "abb2": "LR",
    "abb3": "LBR",
    "code": "430"
  },
  {
    "cn": "利比亚",
    "en": "Libyan Arab Jamahiriya",
    "full": "the Socialist People's Libyan Arab Jamahiriya",
    "abb2": "LY",
    "abb3": "LBY",
    "code": "434"
  },
  {
    "cn": "列支敦士登",
    "en": "Liechtenstein",
    "full": "the Principality of Liechtenstein",
    "abb2": "LI",
    "abb3": "LIE",
    "code": "438"
  },
  {
    "cn": "立陶宛",
    "en": "Lithuania",
    "full": "the Republic of Lithuania",
    "abb2": "LT",
    "abb3": "LTU",
    "code": "440"
  },
  {
    "cn": "卢森堡",
    "en": "Luxembourg",
    "full": "the Grand Duchy of Luxembourg",
    "abb2": "LU",
    "abb3": "LUX",
    "code": "442"
  },
  {
    "cn": "澳门",
    "en": "Macao",
    "full": "Macao Special Administrative Region of China",
    "abb2": "MO",
    "abb3": "MAC",
    "code": "446"
  },
  {
    "cn": "前南马其顿",
    "en": "Macedonia (the former Yugoslav Republic of)",
    "full": "the former Yugoslav Republic of Macedonia",
    "abb2": "MK",
    "abb3": "MKD",
    "code": "807"
  },
  {
    "cn": "马达加斯加",
    "en": "Madagascar",
    "full": "the Republic of Madagascar",
    "abb2": "MG",
    "abb3": "MDG",
    "code": "450"
  },
  {
    "cn": "马拉维",
    "en": "Malawi",
    "full": "the Republic of Malawi",
    "abb2": "MW",
    "abb3": "MWI",
    "code": "454"
  },
  {
    "cn": "马来西亚",
    "en": "Malaysia",
    "full": "Malaysia",
    "abb2": "MY",
    "abb3": "MYS",
    "code": "458"
  },
  {
    "cn": "马尔代夫",
    "en": "Maldives",
    "full": "the Republic of Maldives",
    "abb2": "MV",
    "abb3": "MDV",
    "code": "462"
  },
  {
    "cn": "马里",
    "en": "Mali",
    "full": "the Republic of Mali",
    "abb2": "ML",
    "abb3": "MLI",
    "code": "466"
  },
  {
    "cn": "马耳他",
    "en": "Malta",
    "full": "the Republic of Malta",
    "abb2": "MT",
    "abb3": "MLT",
    "code": "470"
  },
  {
    "cn": "马绍尔群岛",
    "en": "Marshall Islands",
    "full": "the Republic of the Marshall Islands",
    "abb2": "MH",
    "abb3": "MHL",
    "code": "584"
  },
  {
    "cn": "马提尼克",
    "en": "Martinique",
    "full": "Martinique",
    "abb2": "MQ",
    "abb3": "MTQ",
    "code": "474"
  },
  {
    "cn": "毛利塔尼亚",
    "en": "Mauritania",
    "full": "the Islamic Republic of Mauritania",
    "abb2": "MR",
    "abb3": "MRT",
    "code": "478"
  },
  {
    "cn": "毛里求斯",
    "en": "Mauritius",
    "full": "the Republic of Mauritius",
    "abb2": "MU",
    "abb3": "MUS",
    "code": "480"
  },
  {
    "cn": "马约特",
    "en": "Mayotte",
    "full": "Mayotte",
    "abb2": "YT",
    "abb3": "MYT",
    "code": "175"
  },
  {
    "cn": "墨西哥",
    "en": "Mexico",
    "full": "the United Mexican States",
    "abb2": "MX",
    "abb3": "MEX",
    "code": "484"
  },
  {
    "cn": "密克罗尼西亚联邦",
    "en": "Micronesia (the Federated States of)",
    "full": "the Federated States of Micronesia",
    "abb2": "FM",
    "abb3": "FSM",
    "code": "583"
  },
  {
    "cn": "摩尔多瓦",
    "en": "Moldova (the Republic of)",
    "full": "the Republic of Moldova",
    "abb2": "MD",
    "abb3": "MDA",
    "code": "498"
  },
  {
    "cn": "摩纳哥",
    "en": "Monaco",
    "full": "the Principality of Monaco",
    "abb2": "MC",
    "abb3": "MCO",
    "code": "492"
  },
  {
    "cn": "蒙古",
    "en": "Mongolia",
    "full": "Mongolia",
    "abb2": "MN",
    "abb3": "MNG",
    "code": "496"
  },
  {
    "cn": "黑山",
    "en": "Montenegro",
    "full": "he Republic of Montenegro",
    "abb2": "ME",
    "abb3": "MNE",
    "code": "499"
  },
  {
    "cn": "蒙特塞拉特",
    "en": "Montserrat",
    "full": "Montserrat",
    "abb2": "MS",
    "abb3": "MSR",
    "code": "500"
  },
  {
    "cn": "摩洛哥",
    "en": "Morocco",
    "full": "the Kingdom of Morocco",
    "abb2": "MA",
    "abb3": "MAR",
    "code": "504"
  },
  {
    "cn": "莫桑比克",
    "en": "Mozambique",
    "full": "the Republic of Mozambique",
    "abb2": "MZ",
    "abb3": "MOZ",
    "code": "508"
  },
  {
    "cn": "缅甸",
    "en": "Myanmar",
    "full": "the Union of Myanmar",
    "abb2": "MM",
    "abb3": "MMR",
    "code": "104"
  },
  {
    "cn": "纳米比亚",
    "en": "Namibia",
    "full": "the Republic of Namibia",
    "abb2": "NA",
    "abb3": "NAM",
    "code": "516"
  },
  {
    "cn": "瑙鲁",
    "en": "Nauru",
    "full": "the Republic of Nauru",
    "abb2": "NR",
    "abb3": "NRU",
    "code": "520"
  },
  {
    "cn": "尼泊尔",
    "en": "Nepal",
    "full": "Nepal",
    "abb2": "NP",
    "abb3": "NPL",
    "code": "524"
  },
  {
    "cn": "荷兰",
    "en": "Netherlands",
    "full": "the Kingdom of the Netherlands",
    "abb2": "NL",
    "abb3": "NLD",
    "code": "528"
  },
  {
    "cn": "荷属安的列斯",
    "en": "Netherlands Antilles",
    "full": "Netherlands Antilles",
    "abb2": "AN",
    "abb3": "ANT",
    "code": "530"
  },
  {
    "cn": "新喀里多尼亚",
    "en": "New Caledonia",
    "full": "New Caledonia",
    "abb2": "NC",
    "abb3": "NCL",
    "code": "540"
  },
  {
    "cn": "新西兰",
    "en": "New Zealand",
    "full": "New Zealand",
    "abb2": "NZ",
    "abb3": "NZL",
    "code": "554"
  },
  {
    "cn": "尼加拉瓜",
    "en": "Nicaragua",
    "full": "the Republic of Nicaragua",
    "abb2": "NI",
    "abb3": "NIC",
    "code": "558"
  },
  {
    "cn": "尼日尔",
    "en": "Niger",
    "full": "the Republic of the Niger",
    "abb2": "NE",
    "abb3": "NER",
    "code": "562"
  },
  {
    "cn": "尼日利亚",
    "en": "Nigeria",
    "full": "the Federal Republic of Nigeria",
    "abb2": "NG",
    "abb3": "NGA",
    "code": "566"
  },
  {
    "cn": "纽埃",
    "en": "Niue",
    "full": "the Republic of Niue",
    "abb2": "NU",
    "abb3": "NIU",
    "code": "570"
  },
  {
    "cn": "诺福克岛",
    "en": "Norfolk Island",
    "full": "Norfolk Island",
    "abb2": "NF",
    "abb3": "NFK",
    "code": "574"
  },
  {
    "cn": "北马里亚纳",
    "en": "Northern Mariana Islands",
    "full": "the Commonwealth of the Northern Mariana Islands",
    "abb2": "MP",
    "abb3": "MNP",
    "code": "580"
  },
  {
    "cn": "挪威",
    "en": "Norway",
    "full": "the Kingdom of Norway",
    "abb2": "NO",
    "abb3": "NOR",
    "code": "578"
  },
  {
    "cn": "阿曼",
    "en": "Oman",
    "full": "the Sultanate of Oman",
    "abb2": "OM",
    "abb3": "OMN",
    "code": "512"
  },
  {
    "cn": "巴基斯坦",
    "en": "Pakistan",
    "full": "the Islamic Republic of Pakistan",
    "abb2": "PK",
    "abb3": "PAK",
    "code": "586"
  },
  {
    "cn": "帕劳",
    "en": "Palau",
    "full": "the Republic of Palau",
    "abb2": "PW",
    "abb3": "PLW",
    "code": "585"
  },
  {
    "cn": "巴勒斯坦",
    "en": "Palestinian Territory (the Occupied)",
    "full": "the Occupied Palestinian Territory",
    "abb2": "PS",
    "abb3": "PSE",
    "code": "275"
  },
  {
    "cn": "巴拿马",
    "en": "Panama",
    "full": "the Republic of Panama",
    "abb2": "PA",
    "abb3": "PAN",
    "code": "591"
  },
  {
    "cn": "巴布亚新几内亚",
    "en": "Papua New Guinea",
    "full": "Papua New Guinea",
    "abb2": "PG",
    "abb3": "PNG",
    "code": "598"
  },
  {
    "cn": "巴拉圭",
    "en": "Paraguay",
    "full": "the Republic of Paraguay",
    "abb2": "PY",
    "abb3": "PRY",
    "code": "600"
  },
  {
    "cn": "秘鲁",
    "en": "Peru",
    "full": "the Republic of Peru",
    "abb2": "PE",
    "abb3": "PER",
    "code": "604"
  },
  {
    "cn": "菲律宾",
    "en": "Philippines",
    "full": "the Republic of the Philippines",
    "abb2": "PH",
    "abb3": "PHL",
    "code": "608"
  },
  {
    "cn": "皮特凯恩",
    "en": "Pitcairn",
    "full": "Pitcairn",
    "abb2": "PN",
    "abb3": "PCN",
    "code": "612"
  },
  {
    "cn": "波兰",
    "en": "Poland",
    "full": "the Republic of Poland",
    "abb2": "PL",
    "abb3": "POL",
    "code": "616"
  },
  {
    "cn": "葡萄牙",
    "en": "Portugal",
    "full": "the Portuguese Republic",
    "abb2": "PT",
    "abb3": "PRT",
    "code": "620"
  },
  {
    "cn": "波多黎各",
    "en": "Puerto Rico",
    "full": "Puerto Rico",
    "abb2": "PR",
    "abb3": "PRI",
    "code": "630"
  },
  {
    "cn": "卡塔尔",
    "en": "Qatar",
    "full": "the State of Qatar",
    "abb2": "QA",
    "abb3": "QAT",
    "code": "634"
  },
  {
    "cn": "留尼汪",
    "en": "Réunion",
    "full": "Réunion",
    "abb2": "RE",
    "abb3": "REU",
    "code": "638"
  },
  {
    "cn": "罗马尼亚",
    "en": "Romania",
    "full": "Romania",
    "abb2": "RO",
    "abb3": "ROU",
    "code": "642"
  },
  {
    "cn": "俄罗斯联邦",
    "en": "Russian Federation",
    "full": "the Russian Federation",
    "abb2": "RU",
    "abb3": "RUS",
    "code": "643"
  },
  {
    "cn": "卢旺达",
    "en": "Rwanda",
    "full": "the Republic of Rwanda",
    "abb2": "RW",
    "abb3": "RWA",
    "code": "646"
  },
  {
    "cn": "圣赫勒拿",
    "en": "Saint Helena",
    "full": "Saint Helena",
    "abb2": "SH",
    "abb3": "SHN",
    "code": "654"
  },
  {
    "cn": "圣基茨和尼维斯",
    "en": "Saint Kitts and Nevis",
    "full": "Saint Kitts and Nevis",
    "abb2": "KN",
    "abb3": "KNA",
    "code": "659"
  },
  {
    "cn": "圣卢西亚",
    "en": "Saint Lucia",
    "full": "Saint Lucia",
    "abb2": "LC",
    "abb3": "LCA",
    "code": "662"
  },
  {
    "cn": "圣皮埃尔和密克隆",
    "en": "Saint Pierre and Miquelon",
    "full": "Saint Pierre and Miquelon",
    "abb2": "PM",
    "abb3": "SPM",
    "code": "666"
  },
  {
    "cn": "圣文森特和格林纳丁斯",
    "en": "Saint Vincent and the Grenadines",
    "full": "Saint Vincent and the Grenadines",
    "abb2": "VC",
    "abb3": "VCT",
    "code": "670"
  },
  {
    "cn": "萨摩亚",
    "en": "Samoa",
    "full": "the Independent State of Samoa",
    "abb2": "WS",
    "abb3": "WSM",
    "code": "882"
  },
  {
    "cn": "圣马力诺",
    "en": "San Marino",
    "full": "the Republic of San Marino",
    "abb2": "SM",
    "abb3": "SMR",
    "code": "674"
  },
  {
    "cn": "圣多美和普林西比",
    "en": "Sao Tome and Principe",
    "full": "the Democratic Republic of Sao Tome and Principe",
    "abb2": "ST",
    "abb3": "STP",
    "code": "678"
  },
  {
    "cn": "沙特阿拉伯",
    "en": "Saudi Arabia",
    "full": "the Kingdom of Saudi Arabia",
    "abb2": "SA",
    "abb3": "SAU",
    "code": "682"
  },
  {
    "cn": "塞内加尔",
    "en": "Senegal",
    "full": "the Republic of Senegal",
    "abb2": "SN",
    "abb3": "SEN",
    "code": "686"
  },
  {
    "cn": "塞尔维亚",
    "en": "Serbia",
    "full": "the Republic of Serbia",
    "abb2": "RS",
    "abb3": "SRB",
    "code": "688"
  },
  {
    "cn": "塞舌尔",
    "en": "Seychelles",
    "full": "the Republic of Seychelles",
    "abb2": "SC",
    "abb3": "SYC",
    "code": "690"
  },
  {
    "cn": "塞拉利昂",
    "en": "Sierra Leone",
    "full": "the Republic of Sierra Leone",
    "abb2": "SL",
    "abb3": "SLE",
    "code": "694"
  },
  {
    "cn": "新加坡",
    "en": "Singapore",
    "full": "the Republic of Singapore",
    "abb2": "SG",
    "abb3": "SGP",
    "code": "702"
  },
  {
    "cn": "斯洛伐克",
    "en": "Slovakia",
    "full": "the Slovak Republic",
    "abb2": "SK",
    "abb3": "SVK",
    "code": "703"
  },
  {
    "cn": "斯洛文尼亚",
    "en": "Slovenia",
    "full": "the Republic of Slovenia",
    "abb2": "SI",
    "abb3": "SVN",
    "code": "705"
  },
  {
    "cn": "所罗门群岛",
    "en": "Solomon Islands",
    "full": "Solomon Islands",
    "abb2": "SB",
    "abb3": "SLB",
    "code": "090"
  },
  {
    "cn": "索马里",
    "en": "Somalia",
    "full": "the Somali Republic",
    "abb2": "SO",
    "abb3": "SOM",
    "code": "706"
  },
  {
    "cn": "南非",
    "en": "South Africa",
    "full": "the Republic of South Africa",
    "abb2": "ZA",
    "abb3": "ZAF",
    "code": "710"
  },
  {
    "cn": "南乔治亚岛和南桑德韦奇岛",
    "en": "South Georgia and the South Sandwich Islands",
    "full": "South Georgia and the South Sandwich Islands",
    "abb2": "GS",
    "abb3": "SGS",
    "code": "239"
  },
  {
    "cn": "西班牙",
    "en": "Spain",
    "full": "the Kingdom of Spain",
    "abb2": "ES",
    "abb3": "ESP",
    "code": "724"
  },
  {
    "cn": "斯里兰卡",
    "en": "Sri Lanka",
    "full": "the Democratic Socialist Republic of Sri Lanka",
    "abb2": "LK",
    "abb3": "LKA",
    "code": "144"
  },
  {
    "cn": "苏丹",
    "en": "Sudan",
    "full": "the Republic of the Sudan",
    "abb2": "SD",
    "abb3": "SDN",
    "code": "736"
  },
  {
    "cn": "苏里南",
    "en": "Suriname",
    "full": "the Republic of Suriname",
    "abb2": "SR",
    "abb3": "SUR",
    "code": "740"
  },
  {
    "cn": "苏格兰",
    "en": "Scotland",
    "full": "the Republic of Scotland",
    "abb2": "SCO",
    "abb3": "SCO",
    "code": "740"
  },
  {
    "cn": "斯瓦尔巴岛和扬马延岛",
    "en": "Svalbard and Jan Mayen",
    "full": "Svalbard and Jan Mayen",
    "abb2": "SJ",
    "abb3": "SJM",
    "code": "744"
  },
  {
    "cn": "斯威士兰",
    "en": "Swaziland",
    "full": "the Kingdom of Swaziland",
    "abb2": "SZ",
    "abb3": "SWZ",
    "code": "748"
  },
  {
    "cn": "瑞典",
    "en": "Sweden",
    "full": "the Kingdom of Sweden",
    "abb2": "SE",
    "abb3": "SWE",
    "code": "752"
  },
  {
    "cn": "瑞士",
    "en": "Switzerland",
    "full": "the Swiss Confederation",
    "abb2": "CH",
    "abb3": "CHE",
    "code": "756"
  },
  {
    "cn": "叙利亚",
    "en": "Syrian Arab Republic",
    "full": "the Syrian Arab Republic",
    "abb2": "SY",
    "abb3": "SYR",
    "code": "760"
  },
  {
    "cn": "塔吉克斯坦",
    "en": "Tajikistan",
    "full": "the Republic of Tajikistan",
    "abb2": "TJ",
    "abb3": "TJK",
    "code": "762"
  },
  {
    "cn": "坦桑尼亚",
    "en": "Tanzania,United Republic of",
    "full": "the United Republic of Tanzania",
    "abb2": "TZ",
    "abb3": "TZA",
    "code": "834"
  },
  {
    "cn": "泰国",
    "en": "Thailand",
    "full": "the Kingdom of Thailand",
    "abb2": "TH",
    "abb3": "THA",
    "code": "764"
  },
  {
    "cn": "东帝汶",
    "en": "Timor-Leste",
    "full": "the Democratic Republic of Timor-Leste",
    "abb2": "TL",
    "abb3": "TLS",
    "code": "626"
  },
  {
    "cn": "多哥",
    "en": "Togo",
    "full": "the Togolese Republic",
    "abb2": "TG",
    "abb3": "TGO",
    "code": "768"
  },
  {
    "cn": "托克劳",
    "en": "Tokelau",
    "full": "Tokelau",
    "abb2": "TK",
    "abb3": "TKL",
    "code": "772"
  },
  {
    "cn": "汤加",
    "en": "Tonga",
    "full": "the Kingdom of Tonga",
    "abb2": "TO",
    "abb3": "TON",
    "code": "776"
  },
  {
    "cn": "特立尼达和多巴哥",
    "en": "Trinidad and Tobago",
    "full": "the Republic of Trinidad and Tobago",
    "abb2": "TT",
    "abb3": "TTO",
    "code": "780"
  },
  {
    "cn": "突尼斯",
    "en": "Tunisia",
    "full": "the Republic of Tunisia",
    "abb2": "TN",
    "abb3": "TUN",
    "code": "788"
  },
  {
    "cn": "土耳其",
    "en": "Turkey",
    "full": "the Republic of Turkey",
    "abb2": "TR",
    "abb3": "TUR",
    "code": "792"
  },
  {
    "cn": "土库曼斯坦",
    "en": "Turkmenistan",
    "full": "Turkmenistan",
    "abb2": "TM",
    "abb3": "TKM",
    "code": "795"
  },
  {
    "cn": "特克斯和凯科斯群岛",
    "en": "Turks and Caicos Islands",
    "full": "Turks and Caicos Islands",
    "abb2": "TC",
    "abb3": "TCA",
    "code": "796"
  },
  {
    "cn": "图瓦卢",
    "en": "Tuvalu",
    "full": "Tuvalu",
    "abb2": "TV",
    "abb3": "TUV",
    "code": "798"
  },
  {
    "cn": "乌干达",
    "en": "Uganda",
    "full": "the Republic of Uganda",
    "abb2": "UG",
    "abb3": "UGA",
    "code": "800"
  },
  {
    "cn": "乌克兰",
    "en": "Ukraine",
    "full": "Ukraine",
    "abb2": "UA",
    "abb3": "UKR",
    "code": "804"
  },
  {
    "cn": "阿联酋",
    "en": "United Arab Emirates",
    "full": "the United Arab Emirates",
    "abb2": "AE",
    "abb3": "ARE",
    "code": "784"
  },
  {
    "cn": "英国",
    "en": "United Kingdom",
    "full": "the United Kingdom of Great Britain and Northern Ireland",
    "abb2": "GB",
    "abb3": "GBR",
    "code": "826"
  },
  {
    "cn": "美国",
    "en": "United States",
    "full": "the United States of America",
    "abb2": "US",
    "abb3": "USA",
    "code": "840"
  },
  {
    "cn": "美国本土外小岛屿",
    "en": "United States Minor Outlying Islands",
    "full": "United States Minor Outlying Islands",
    "abb2": "UM",
    "abb3": "UMI",
    "code": "581"
  },
  {
    "cn": "乌拉圭",
    "en": "Uruguay",
    "full": "the Eastern Republic of Uruguay",
    "abb2": "UY",
    "abb3": "URY",
    "code": "858"
  },
  {
    "cn": "乌兹别克斯坦",
    "en": "Uzbekistan",
    "full": "the Republic of Uzbekistan",
    "abb2": "UZ",
    "abb3": "UZB",
    "code": "860"
  },
  {
    "cn": "瓦努阿图",
    "en": "Vanuatu",
    "full": "the Republic of Vanuatu",
    "abb2": "VU",
    "abb3": "VUT",
    "code": "548"
  },
  {
    "cn": "委内瑞拉",
    "en": "Venezuela",
    "full": "the Bolivarian Republic of Venezuela",
    "abb2": "VE",
    "abb3": "VEN",
    "code": "862"
  },
  {
    "cn": "越南",
    "en": "Viet Nam",
    "full": "the Socialist Republic of Viet Nam",
    "abb2": "VN",
    "abb3": "VNM",
    "code": "704"
  },
  {
    "cn": "英属维尔京群岛",
    "en": "Virgin Islands (British)",
    "full": "British Virgin Islands",
    "abb2": "VG",
    "abb3": "VGB",
    "code": "092"
  },
  {
    "cn": "美属维尔京群岛",
    "en": "Virgin Islands (U.S.)",
    "full": "the Virgin Islands of the United States",
    "abb2": "VI",
    "abb3": "VIR",
    "code": "850"
  },
  {
    "cn": "瓦利斯和富图纳",
    "en": "Wallis and Futuna",
    "full": "Wallis and Futuna Islands",
    "abb2": "WF",
    "abb3": "WLF",
    "code": "876"
  },
  {
    "cn": "西撒哈拉",
    "en": "Western Sahara",
    "full": "Western Sahara",
    "abb2": "EH",
    "abb3": "ESH",
    "code": "732"
  },
  {
    "cn": "也门",
    "en": "Yemen",
    "full": "the Republic of Yemen",
    "abb2": "YE",
    "abb3": "YEM",
    "code": "887"
  },
  {
    "cn": "赞比亚",
    "en": "Zambia",
    "full": "the Republic of Zambia",
    "abb2": "ZM",
    "abb3": "ZMB",
    "code": "894"
  },
  {
    "cn": "津巴布韦",
    "en": "Zimbabwe",
    "full": "the Republic of Zimbabwe",
    "abb2": "ZW",
    "abb3": "ZWE",
    "code": "716"
  }
]
