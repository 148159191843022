<template>
  <div id="app" :data-theme="themeName">
    <router-view/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'navBar',
  computed: {
    ...mapGetters(['themeName'])
  },
  mounted() {
    document.body.setAttribute('data-theme', this.themeName)
  },
  watch: {
    themeName() {
      document.body.setAttribute('data-theme', this.themeName)
    }
  }
}
</script>
<style lang="scss">
  @import "~@/assets/fonts/fonts.css";
  @import "//at.alicdn.com/t/c/font_3998326_umv6tmlukfa.css";
</style>
