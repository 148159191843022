import {callNetwork} from "@/api/common";
import {Message, MessageBox} from "element-ui";
const _Message = Message
const _MessageBox = MessageBox
export function arrRandom(arr) {
  var index = Math.floor((Math.random() * arr.length)) || 0
  return arr[index]
}

export function phoneVerif(val) {
  var check = /^[1][3-9]\d{9}$|^([6|9])\d{7}$|^[0][9]\d{8}$|^[6]([8|6])\d{5}$/
  return !check.test(val)
}

export function numberInt(val) {
  var check = /(^[1-9]\d*$)/
  return !check.test(val)
}

export function checkCardNumber(val, msg) {
  // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
  var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  return !reg.test(val)
}

// !(/(^[1-9]\d*$)/.test(places))
export function dateFormat(date, format = "yyyy-MM-dd hh:mm:ss") {
  var dateObj = new Date(date)
  var o = {
    'y+': dateObj.getFullYear(), //month
    'M+': dateObj.getMonth() + 1, //month
    'd+': dateObj.getDate(),    //day
    'h+': dateObj.getHours(),   //hour
    'm+': dateObj.getMinutes(), //minute
    's+': dateObj.getSeconds(), //second
    'q+': Math.floor((dateObj.getMonth() + 3) / 3),  //quarter
    'S': dateObj.getMilliseconds() //millisecond
  }

  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1,
      (dateObj.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return format
}

export function transDate(strtime) {
  var date = new Date(strtime.replace(/-/g, '/'))
  date = Date.parse(date)
  var tt = new Date(parseInt(date))
  var days = parseInt((new Date().getTime() - date) / 86400000)
  var today = new Date().getDate()
  var year = tt.getFullYear()
  var mouth = tt.getMonth() + 1
  var day = tt.getDate()
  var time = tt.getHours() < 10 ? '0' + tt.getHours() : tt.getHours()
  var min = tt.getMinutes() < 10 ? '0' + tt.getMinutes() : tt.getMinutes()
  var result, offset
  offset = Math.abs(today - day)
  if (days < 2 && offset < 2) {
    if (offset === 0) {
      result = time + ':' + min
    } else if (offset === 1) {
      result = '昨天'
    }
  } else {
    result = year + '-' + mouth + '-' + day
  }
  return result
}

export function dateDiff(startTime, endTime, showSeconds = true) {
  if (!startTime || !endTime) {
    return ''
  }
  var date1 = new Date(startTime)
  var date2 = new Date(endTime)

  var date3 = date2.getTime() - date1.getTime() //时间差的毫秒数
  //计算出相差天数
  var days = Math.floor(date3 / (24 * 3600 * 1000))
  //计算出小时数

  var leave1 = date3 % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
  var hours = Math.floor(leave1 / (3600 * 1000))
  //计算相差分钟数
  var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
  var minutes = Math.floor(leave2 / (60 * 1000))

  //计算相差秒数

  var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
  var seconds = Math.round(leave3 / 1000)
  var str = ''
  if (days) {
    str += days + '天 '
  }
  str += (hours < 10 ? '0' + hours : hours) + ':'
  str += (minutes < 10 ? '0' + minutes : minutes)
  if (showSeconds) {
    str += ':' + (seconds < 10 ? ('0' + seconds) : seconds)
  }
  return str
}

// 正计时
export function timingTime(time) {
  time = formatTimestamp(time)
  let startTime = new Date(time).getTime()
  let currentTime = new Date().getTime()
  let difference = currentTime - startTime
  let m = Math.floor(difference / (1000))
  let mm = Math.max(m % 60, 0)  // 秒
  mm = mm < 10 ? '0' + mm : mm
  let f = Math.floor(m / 60)
  let ff = Math.max(f % 60, 0) // 分钟
  ff = ff < 10 ? '0' + ff : ff
  let s = Math.floor(f / 60) // 小时
  let ss = Math.max(s % 24, 0)
  ss = ss > 0 ? (ss < 10 ? '0' + ss : ss) + ':' : ''
  let day = Math.floor(s / 24) // 天数
  day = day > 0 ? day + '天 ' : ''
  return day + ss + ff + ":" + mm
}

// 倒计时
export function timingDown(time) {
  time = formatTimestamp(time)
  let startTime = new Date(time).getTime()
  let currentTime = new Date().getTime()
  let difference = startTime - currentTime
  let m = Math.floor(difference / (1000))
  let mm = Math.max(m % 60, 0)  // 秒
  mm = mm < 10 ? '0' + mm : mm
  let f = Math.floor(m / 60)
  let ff = Math.max(f % 60, 0) // 分钟
  ff = ff < 10 ? '0' + ff : ff
  let s = Math.floor(f / 60) // 小时
  let ss = Math.max(s % 24, 0)
  ss = ss > 0 ? (ss < 10 ? '0' + ss : ss) + ':' : ''
  let day = Math.floor(s / 24) // 天数
  day = day > 0 ? day + '天 ' : ''
  return ss ? (ss + ff) : (ss + ff + ":" + mm)
}

// 时间戳转日期
export function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);  // 月份从0开始，需要加1；使用slice函数补齐前导0
  const day = ("0" + date.getDate()).slice(-2);
  const hour = ("0" + date.getHours()).slice(-2);
  const minute = ("0" + date.getMinutes()).slice(-2);
  const second = ("0" + date.getSeconds()).slice(-2);
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

// 姓名隐藏*
export function nameStar(name) {
  var newStr;
  if (name.length === 2) {
    newStr = name.substr(0, 1) + '*';
  } else if (name.length > 2) {
    var char = '';
    for (var i = 0, len = name.length - 2; i < len; i++) {
      char += '*';
    }
    newStr = name.substr(0, 1) + char + name.substr(-1, 1);
  } else {
    newStr = name;
  }
  return newStr;
}

// 金额格式化
export function formatAmount(amount, keepDecimal = 2, fillDec = false, roundInteger = true) {
  // 处理四舍五入保留整数的情况
  amount = parseFloat(amount)
  if (roundInteger) {
    amount = Math.round(amount);
  }

  // 转换为字符串，方便后续处理
  let amountStr = amount.toString();

  // 处理保留两位小数的情况
  if (keepDecimal) {
    const decimalIndex = amountStr.indexOf('.');

    if (decimalIndex !== -1) {
      // 存在小数部分
      const decimalPart = amountStr.substring(decimalIndex + 1);

      if (decimalPart.length > keepDecimal) {
        // 超过两位小数，截取并去除多余的0
        amountStr = amount.toFixed(2).replace(/(\.0*|(?<=\.\d)0*)$/, '');
      } else {
        // 小数部分不超过两位，直接去除多余的0
        amountStr = amountStr.replace(/(\.0*|(?<=\.\d)0*)$/, '');
      }
    } else {
      if (fillDec) {
        // 无小数部分，补充两位小数
        amountStr += '.00';
      }
    }
  } else {
    // 不保留小数，直接去除小数部分
    amountStr = Math.floor(amount).toString();
  }

  return amountStr;
}

// 卡号隐藏
export function starCardNumber(number) {
  let len = number.substring(2, number.length - 4).length
  let str = ''
  for (let i = 0; i < len; i++) {
    str += '*'
  }
  return number.replace(/(.{2}).*(.{4})/, "$1******$2")
}

// 手机号隐藏
export function startPhone(phone) {
  if (phone) {
    return phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
  } else {
    return ''
  }
}

// 呼叫
let callLoading = false
export function call(showConfirm = false) {
  let timestamp = new Date().getTime()
  let oldTime = localStorage.getItem('oldTime')
  if (oldTime) {
    // 判断是否有30s超过了可以继续呼叫
    let times = Math.abs(timestamp - oldTime) >= (0.5 * 60 * 1000)
    if (!times) {
      _Message.error('呼叫太频繁了~')
      return
    }
  }
  const func = () => {
    if (callLoading) return
    callLoading = true
    callNetwork({type: 1, message: ''}).then(res => {
      if (res.code == 200) {
        localStorage.setItem('oldTime', res.data.time)
        _Message.success('已呼叫网管,请稍等~')
      }
    }).finally(() => {
      callLoading = false
    })
  }
  if (showConfirm) {
    _MessageBox.confirm('是否呼叫网管?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      func()
    }).catch(() => {})
    return
  }
  func()
}
