import http from './fetch.js';

// 上机消费记录
export function onlineRecord(params = {}) {
    return http({
        url: '/web/client/onlineRecord',
        method: 'get',
        params: params
    });
}
// 充值记录
export function rechargeList(params = {}) {
    return http({
        url: '/web/client/rechargeList',
        method: 'get',
        params: params
    });
}
// 充值活动
export function activityData(params = {}) {
  return http({
    url: '/web/client/user/activity',
    method: 'get',
    params: params
  });
}

// 充值网费-创建订单
export function clientRecharge(params = {}) {
  return http({
    url: '/web/client/user/clientRecharge',
    method: 'get',
    params: params
  });
}
// 充值网费-支付订单
export function clientPay(params = {}) {
  return http({
    url: '/web/client/clientPay',
    method: 'get',
    params: params
  });
}
// 充值网费-轮询状态
export function rechargePayStatus(params = {}) {
  return http({
    url: '/web/client/rechargePayStatus',
    method: 'get',
    params: params
  });
}

// 余额流水
export function balanceBill(params = {}) {
  return http({
    url: '/web/client/user/balanceBill',
    method: 'get',
    params: params
  });
}
// 优惠券列表
export function getCouponList(params = {}) {
  return http({
    url: '/web/client/user/couponList',
    method: 'get',
    params: params
  });
}
// 常转增人
export function givingRegular(params = {}) {
  return http({
    url: '/web/client/user/givingRegular',
    method: 'get',
    params: params
  });
}
// 转赠优惠券
export function givenOnCoupon(params = {}) {
  return http({
    url: '/web/client/user/givenOnCoupon',
    method: 'get',
    params: params
  });
}

// 充值排行
export function rechargeRanking(params = {}) {
  return http({
    url: '/web/client/user/rechargeRanking',
    method: 'get',
    params: params
  });
}
