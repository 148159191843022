import http from './fetch.js';

export function signInList(params = {}) {
  return http({
    url: '/web/client/sign/getList',
    method: 'get',
    params: params
  });
}
// 签到-提交
export function signIn(params = {}) {
  return http({
    url: '/web/client/sign/saveDay',
    method: 'get',
    params: params
  });
}
// 累计签到
export function saveCycle(params = {}) {
  return http({
    url: '/web/client/sign/saveCycle',
    method: 'get',
    params: params
  });
}
// 签到排行榜
export function signRankingList(params = {}) {
  return http({
    url: '/web/client/sign/signRankingList',
    method: 'get',
    params: params
  });
}
