import http from './fetch.js';

// 包时列表
export function rateActivity(params = {}) {
  return http({
    url: '/web/client/getRateActivity',
    method: 'get',
    params: params
  });
}

// 包时
export function addOnLineRateActivity(params = {}) {
  return http({
    url: '/web/client/addOnLineRateActivity',
    method: 'get',
    params: params
  });
}

// 包时卡列表
export function packetTimeCardList(params = {}) {
  return http({
    url: '/web/client/getForMemberQuotaCard',
    method: 'get',
    params: params
  });
}

// 使用包时卡
export function useRateQuotaCard(params = {}) {
  return http({
    url: '/web/client/addRateQuotaCard',
    method: 'get',
    params: params
  });
}
