import axios from 'axios'
import qs from 'qs'
import Vue from 'vue'
import store from '../store'
// 允许带上cookie
axios.defaults.withCredentials = true
axios.defaults.timeout = 15000

axios.interceptors.request.use(config => {
  let secret = store.getters.secret;
  if (config.params && config.params.secret) {
    secret = config.params.secret
  }
  if (config.data && config.data.secret) {
    secret = config.data.secret
  }
  config.headers['secret'] = secret
  config.headers['Content-Type'] = 'application/json;charset=UTF-8'
  return config
}, error => {
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use((res) => {

  // 拦截回传的数据，判断是否合法
  var code = res.data.code
  if (res.status == 200) {
    if (code == 403) {
      Vue.prototype.$message.error('登录已过期,请重新登陆!')
      // location.replace("#/login")
    }
    return res.data
  }
  return res.data
}, (error) => {
  console.log(error)
  if (error.response) {
    Vue.prototype.$message.error("系统繁忙");
    // Indicator.close();
  } else {
    Vue.prototype.$message.error("网络不稳定，请稍后再试！");
    // Indicator.close();
  }

  // 对响应错误做处理
  return Promise.reject(error)
})
// data的数据格式如：
// data = {method:"",params:{}}   method：string 请求的方式  params:Obj  请求的数据参数
function http (data = {
  url: '',
  method: '',
  params: {}
}, baseUrl = '/hx-api', withQs = false) {

  // var userInfo = utils.getUserInfo()
  // if(userInfo.token){
  //     data.params.token = userInfo.token;
  // }
  // if(userInfo.id){
  //     data.params.userId = userInfo.id;
  // }
  baseUrl = process.env.VUE_APP_BASE_API_TARGET
  if (data.method === 'post') {
    const fParams = withQs ? qs.stringify(data.params) : data.params
    return axios.post(baseUrl + data.url + `?t=${Math.random()}`, fParams)
    // get
  } else if (data.method === 'get') {
    return axios.get(baseUrl + data.url + `?t=${Math.random()}`, {
      params: data.params
    })
  } else if (data.method === 'delete') {
    return axios.delete(baseUrl + data.url + `?t=${Math.random()}`, {
      params: data.params
    })
  } else if (data.method === 'put') {
    console.log(data)
    return axios.put(baseUrl + data.url + `?t=${Math.random()}`, {
      params: data.params
    })
  }
}

function returnErrorStr (errCode) {
  switch (errCode) {
    case 'SYS.0001':
      return '系统错误'

    default:
      break
  }
}

export default http
