import http from './fetch.js';

// 获取轮播图
export function slideshow(params = {}) {
  return http({
    url: '/web/client/firstPage/slideshow',
    method: 'get',
    params: params
  });
}

// 招聘列表
export function bringInList(params = {}) {
  return http({
    url: '/web/client/bringInList',
    method: 'get',
    params: params
  });
}
// 呼叫网管
export function callNetwork(params = {}) {
  return http({
    url: '/web/client/callNetwork',
    method: 'get',
    params: params
  });
}

// 获取二维码列表
export function qrcodeList(params = {}) {
  return http({
    url: '/web/client/setting/qrcodeList',
    method: 'get',
    params: params
  });
}


