import http from '@/api/fetch'

// 获取竞猜活动
export function guessList(params = {}) {
  return http({
    url: '/web/client/game/list',
    method: 'get',
    params: params
  });
}
// 获取投注记录
export function betList(params = {}) {
  return http({
    url: '/web/client/game/bet/list',
    method: 'get',
    params: params
  });
}
// 投注
export function guessBet(params = {}) {
  return http({
    url: '/web/client/game/bet',
    method: 'get',
    params: params
  });
}
