import http from './fetch.js';

// 获取一级分类列表
export function getCategoryList(params = {}) {
    return http({
        url: '/web/client/firstList',
        method: 'get',
        params: params
    });
}

// 获取二级分类列表
export function getSubCategoryList(params = {}) {
    return http({
        url: '/web/client/secondList',
        method: 'get',
        params: params
    });
}

// 获取优惠券列表
export function getCouponList(params = {}) {
    return http({
        url: '/web/client/shopCouponList',
        method: 'post',
        params: params
    });
}

// 获取支付前订单信息
export function refreshGoodsOrder(params = {}) {
  return http({
      url: '/web/client/refreshOrder',
      method: 'post',
      params: params
  });
}

// 获取商品列表
export function getGoodsList(params = {}) {
    return http({
        url: '/web/client/goodsList',
        method: 'get',
        params: params
    });
}

// 创建商品订单
export function createGoodsOrder(params = {}) {
    return http({
        url: '/web/client/createClientOrder',
        method: 'get',
        params: params
    });
}

// 支付
export function payOrder(params = {}) {
    return http({
        url: '/web/client/clientPay',
        method: 'get',
        params: params
    });
}

// 获取支付状态
export function payStatus(params = {}) {
  return http({
      url: '/web/client/payStatus',
      method: 'get',
      params: params
  });
}

// 获取口味
export function goodsTaste(params = {}) {
    return http({
        url: '/web/client/goodsTaste',
        method: 'get',
        params: params
    });
}
