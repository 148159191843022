import http from './fetch.js';

// 获取门店信息
export function getShopInfo(params = {}) {
  return http({
    url: '/web/client/shopInfo',
    method: 'get',
    params: params
  });
}

// 获取门店列表
export function getShopList(params = {}) {
    return http({
        url: '/web/shop/clientShopList',
        method: 'get',
        params: params
    });
}
